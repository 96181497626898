.quote-modal {
  &-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    z-index: 99999;
    padding: 21px;
    display: flex;

    opacity: 0;

    transform: translateX(-100%);

    transition: transform 0.4s cubic-bezier(0.465, 0.183, 0.153, 0.946),
      opacity 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946);

    .close-btn-wrap {
      position: absolute;
      right: 16px;
      top: 16px;

      width: 32px;
      height: 32px;

      img {
        width: 100%;
        height: 100%;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .big-text-wrap {
      position: absolute;
      left: 32px;
      top: 16px;

      h1 {
        font-size: 10em;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $brand-color;
      }

      @media (max-width: 767px) and (orientation: portrait) {
        left: 16px;
        top: 42px;

        h1 {
          font-size: 72px;
        }
      }
    }

    @media (max-width: 767px) and (orientation: portrait) {
      flex-direction: column;
      overflow-y: scroll;
    }
  }

  &-img {
    display: flex;
    align-items: center;
    height: 100%;
    width: 50%;
    flex-shrink: 0;

    & .img-wrap {
      margin: 0 auto;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    @media (max-width: 767px) and (orientation: portrait) {
      width: 100%;
      height: auto;
      margin-top: $space + 7;
    }
  }

  &-form-wrap {
    height: 100%;
    width: 50%;
    padding-left: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 767px) and (orientation: portrait) {
      width: 100%;
      height: auto;
      margin-top: $space + 2;
      padding-left: 0;
    }
  }

  &-form {
    margin: 0 auto;

    .title {
      font-size: 4em;
      line-height: 0.8em;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $white;

      text-transform: uppercase;
      margin-bottom: 16px;

      span {
        -webkit-text-fill-color: $brand-color;
        -webkit-text-stroke-color: transparent;
        -webkit-text-stroke-width: 1px;
      }
    }

    p {
      color: $white;
      font-size: 14px;
      margin: 0 4px;
    }

    form {
      margin-top: 32px;
      position: relative;

      @media (max-width: 767px) and (orientation: portrait) {
        textarea {
          min-height: 90px;
          color: #fff;
        }
      }
    }
  }
}
