@use "sass:map";

.nav-container {
  position: absolute;
  z-index: 1000;
  background-color: transparent;
  width: 100vw;
  height: 94px;
  padding: 12px 26px;

  .nav-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;

    .nav-logo {
      width: 64px;
      height: 64px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    .nav-mobile-icon {
      display: none;
    }

    .nav-links {
      display: flex;
      margin-left: auto;
      color: $white;

      .nav-link {
        font-size: 14px;
        margin: 0 $space;

        &.has-dropdown {
          position: relative;
          cursor: pointer;

          &.active {
            .nested-dropdown {
              display: flex;
            }
          }

          a {
            display: flex;
            gap: 6px;
            align-items: center;
          }

          &:hover {
            a {
              color: #ffffff;
            }
          }

          .nested-dropdown {
            display: none;
            flex-direction: column;

            position: absolute;
            right: 0;
            top: calc(100% + 14px);

            width: 120px;
            height: auto;

            background-color: #ffffff2a;
            backdrop-filter: blur(8px);

            padding: 6px;

            .nav-link {
              margin: 1px 0;
              height: 32px;

              border-radius: 4px;
              padding: 0 6px;

              a {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
              }

              &:hover {
                background-color: #58815c;
              }
            }
          }
        }

        &.link-hidden {
          display: none;
        }

        &.link-active {
          position: relative;

          a {
            font-weight: bold;
            color: #88b78d;
          }

          &::before {
            content: "";
            width: 100%;
            height: 1px;
            background-color: #88b78d;
            position: absolute;
            left: 0;
            bottom: -2px;
          }
        }

        &:last-child {
          margin-right: 0;
        }
        &:first-child {
          margin-left: 0;
        }

        &:hover {
          a {
            color: #88b78d;
          }
        }
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    padding: 6px 12px;
    .nav-wrapper {
      height: 48px;

      .nav-logo,
      .nav-links {
        display: none;
      }

      .nav-mobile-icon {
        display: block;
        width: 30px;
        height: 30px;

        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }
}

.quote-link {
  padding: 6px 16px;
  color: $white;
  font-size: 16px;
  border: 1px solid $white;
  margin-left: $space + 1;

  position: relative;

  display: flex;
  align-items: center;

  gap: 6px;

  cursor: pointer;

  &.active {
    .nested-dropdown {
      display: flex;
    }
  }

  .nested-dropdown {
    display: none;
    flex-direction: column;

    position: absolute;
    right: 0;
    top: calc(100% + 14px);

    width: 120px;
    height: auto;

    background-color: #ffffff2a;
    backdrop-filter: blur(8px);

    padding: 6px;

    .nav-link {
      margin: 1px 0;
      height: 32px;

      border-radius: 4px;
      padding: 0 6px;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }

      &:hover {
        background-color: #58815c;
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    margin-left: auto;
    font-size: 12px;
  }
}

.sidebar-container {
  width: 100%;
  height: 100vh;
  background-image: url("../img/sidebar.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  transition: all 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  transform: translateX(-100%);
  display: none;

  @media (max-width: 767px) and (orientation: portrait) {
    display: block;
  }

  &.active {
    transform: translateX(0);
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    z-index: 110;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 40%,
      rgba(255, 255, 255, 1) 10%,
      rgba(255, 252, 252, 0) 100%
    );

    position: absolute;
    left: 0;
    top: 0;
  }

  .sidebar-wrapper {
    padding: 10px;
    position: relative;
    z-index: 150;

    .nav-mobile-icon {
      display: block;
      width: 30px;
      height: 30px;

      img {
        width: 30px;
        height: 30px;
        object-fit: contain;
        object-position: center;
      }
    }

    .nav-links {
      display: flex;
      margin-left: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #2e3b48;
      margin-top: 48px;

      .nav-link {
        font-size: 28px;
        margin: 0;
        margin-bottom: 32px;
        text-align: center;

        &.link-hidden {
          display: none;
        }

        &.link-active {
          position: relative;

          a {
            font-weight: bold;
          }

          &::before {
            content: "";
            width: 100%;
            height: 3px;
            background-color: #2e3b48;
            position: absolute;
            left: 0;
            bottom: -2px;
          }
        }

        &:last-child {
          margin-right: 0;
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.announcement-container {
  background-color: #58815c;
  width: 100vw;
  min-height: 48px;
  padding: 6px 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: min-height 0.3s ease-out, height 0.3s ease-out, padding 0s linear;

  &.close {
    min-height: 0;
    height: 0;
    padding: 0;

    p,
    a {
      display: none;
    }
  }

  &.permanent-close {
    display: none;
  }

  p {
    margin: 0;
    font-size: 15px;
    color: #ffffff;
    font-weight: 400;

    a {
      text-decoration: underline;
    }
  }

  .cls-btn {
    width: 18px;
    height: 18px;
    margin-left: auto;
    flex-shrink: 0;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
