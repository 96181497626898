//  * Variables
//  * =================================
//  * - Typography
//  * - Breakpoints
//  * - Paths
//  * - Layout & Grid
//  * - App-specific
//  */

/**
 * Global color palette
 * =================================
 * - Generic colors
 * - Color scheme
 * - Common colors
 * - Links
 * - Text selection
 * - <hr>
 * - Any others..
 */

// Color Scheme
$brand-color: #6b9b6e;

// Generic colors
$white: #ffffff;
$blue: #288ad6;
$green: #16c98d;
$red: #fa5e5b;
$yellow: #feef6d;
$orange: #fe7c08;
$pink: #ff708e;
$purple: #8e44ad;
$gray: #95a5a6;

$dark: #29223a;
$bright: #ffffff;

$color-kickoff-blue: #181830;
$color-kickoff-orange: #fe7c08;

// <hr> border color
$hr-color: lighten(#000, 70%);

// 3rd party brand colors - find more at brandcolors.net
$color-twitter: #55acee;
$color-facebook: #3b5998;
$color-youtube: #cd201f;
$color-pinterest: #bd081c;
$color-instagram: #e4405f;
$color-whatsapp: #25d366;

//  * Typography
//  * =================================
//  *
//  * Base sizes:
//  * Set this in pixels (but do not add px),
//  * the font-size mixin will convert to REMS
//  */
$font-size-base: 16;
$font-size-base-px: $font-size-base * 1px;

$leading-base: 1.4;
$line-height-base: round(14 * $leading-base); // 16 * 1.4
$baseline: $line-height-base * 1px;

$type-scale: 1.25; // Major third

$type: (
  micro: $font-size-base-px - 4px,
  // lable, smalll
  small: $font-size-base-px - 2px,
  // h5, h6
  base: 18px,
  // p, h4
  mid: 24px,
  // h3
  large: 36px,
  // h2
  jumbo: 42px // h1,
);

$extra-large-font-size: 64px;

// Useful to set here when bold webfonts come as 400 font-weight.
$font-weight-bold: bold;

// Font stacks
$font-family-sans: "Work Sans", Helvetica, Arial, sans-serif;
$font-family-serif: "Poppins", serif;
$font-family-mono: Menlo, Monaco, "Courier New", monospace;

$font-family-base: $font-family-sans;
$font-family-alt: $font-family-serif;

$font-family-headings: "Anton", "Poppins", Sans-Serif; // using inherit will use $font-family-base for all headings
$font-weight-headings: 400; // instead of browser default, bold
$line-height-headings: 1.4;

//   * Breakpoints
//   * =================================
//   * There are no common breakpoints so these are just a suggestions
//   * You'll need to define your own breakpoints to suit your design
//   * Add your breakpoints to this Sass map
//   * Used in the Kickoff grid
//   */
$breakpoints: (
  narrow: 400px,
  mid: 750px,
  wide: 1000px,
  huge: 1250px,
);

// Setup layout max-width
$layout-margin: 0; // [in pixels] Space outside the grid
$layout-max-width: 1000; // [in pixels]
$layout-min-width: 960; // [in pixels] Minimum width for layout in <IE9
$layout-max-width--huge: 1100; // [in pixels]
$layout-min-width--huge: 1100; // [in pixels] Minimum width for layout in <IE9

/**
  * Grid
  * =================================
  * These variables will control the grid module.
  *
  * if your project doesnt use Kickoff’s default grid, you can delete these
  */

$grid-column-count: 12; // Total column count
$grid-gutter-width: 20; // [in pixels]

//Settings here override & affect the grid classes output
$responsive-grid-sizes: true; // Disable this if you don't want grid sizes for each breakpoint
$use-legacy-grid: true; // Disable this is you on’t want to output .no-flexbox classes for older browsers to use

/**
  * Path variables
  * =================================
  */
$img-path: "/assets/img"; // Usage: background: url('#{$img-path}/img.jpg') 0 0 no-repeat;

/**
  * Z-index variables
  * =================================
  */
$z-index: (
  low: 10,
  mid: 100,
  high: 1000,
) !default;

$z-index-sec: (
  xs: 2,
  sm: 4,
  md: 6,
  lg: 8,
  xl: 10,
);

/**
  * App-specific variables
  * =================================
  */
$default-transition-timing: cubic-bezier(0.77, 0, 0.175, 1);
$default-transition-duration: 300ms;
$default-transition: all $default-transition-duration $default-transition-timing;

/**
 * Spcing Variables
 * =================================
 */
$space: 1rem;
