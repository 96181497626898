.hostel-hero-section {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

  &.bir {
    background-image: url("../img/bir/hero.jpg");
  }
  &.bir-ext {
    background-image: url("../img/bir-ext/hero.jpg");
    &::after {
      background-color: #0000005c;
    }
  }
  &.kasol {
    background-image: url("../img/kasol/hero.jpg");
    &::after {
      background-color: #0000005c;
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #0000003c;
    z-index: 100;
  }
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    z-index: 110;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(255, 252, 252, 0) 40%
    );

    position: absolute;
    left: 0;
    top: 0;
  }

  & .title-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 120;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    h1,
    h2 {
      margin: 0;
      color: #ffffff;
      text-transform: uppercase;
      text-align: center;
    }

    h1 {
      font-size: 10rem;
    }

    h2 {
      color: transparent;
      font-size: 42px;

      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: lighten(#ffffff, 10%);
    }

    p {
      color: #ffffff;
      font-size: 21px;
      margin: 0;
      text-align: center;
      width: 70%;
    }

    & .button-wrap {
      margin-top: 18px;

      &.margin-top {
        margin-top: 36px;
      }

      .btn-1,
      .btn-2 {
        border: 1px solid #ffffff;
        padding: 12px 32px;
        color: #ffffff;
        margin: 0 12px;

        &:hover {
          cursor: pointer;
        }
      }

      .btn-1 {
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: #ffffff;
          color: #000000;
        }
      }

      .btn-2 {
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: #f94601;
          border-color: #f94601;
        }
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    & .title-wrapper {
      h1 {
        font-size: 64px;
        text-align: center;
      }

      h2 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
        text-align: center;
      }

      & .button-wrap {
        .btn-1,
        .btn-2 {
          padding: 12px 28px;
          font-size: 14px;
        }
      }
    }
  }
}

.hostel-about-section {
  width: 100%;
  overflow: hidden;

  .about-block {
    margin: auto 0;
    padding: 0 160px;

    .place-list {
      margin-top: 32px;

      li {
        display: flex;
        align-items: center;
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0;
        }

        .ico {
          width: 36px;
          height: 36px;
          margin-right: 21px;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }

        .detail {
          margin: 0;
        }
      }
    }
  }

  .about-pic-block {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    padding: 0 !important;

    &.bir {
      background-image: url("../img/bir/about.jpg");
      height: 840px;
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      z-index: 110;
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.7) 35%,
        rgba(255, 255, 255, 0) 100%
      );

      position: absolute;
      left: 0;
      top: 0;
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    .row {
      width: 100%;
      margin: 0;
    }

    .about-block {
      margin: auto 0;
      padding: 42px 4%;

      .place-list {
        margin-top: 26px;

        li {
          display: flex;
          align-items: center;
          margin-bottom: 21px;

          .ico {
            width: 26px;
            height: 26px;
            margin-right: 21px;
            flex-shrink: 0;
          }
        }
      }
    }
    .about-pic-block {
      display: none;
    }
  }
}

.hostel-details-section {
  transform: translateY(-260px);
  margin-bottom: -226px;

  .hostel-detail-list {
    margin-top: 32px;
    width: 70%;
    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }

      .ico {
        width: 32px;
        height: 32px;
        margin-right: 21px;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }

      .detail {
        margin: 0;
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    transform: translateY(0);
    margin-bottom: 0;
    padding: 42px 0 32px 0;
    margin-top: 62px;
  }
}

.hostel-bedding-section,
.hostel-events-section,
.hostel-tours-section,
.hostel-cafe-section,
.hostel-details-section {
  padding-top: 32px;

  .title-wrap {
    .ico-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 14px;

      .ico {
        width: 48px;
        height: 48px;
        margin-right: 21px;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }

    .title-h {
      font-weight: 700;
      margin-bottom: 6px;
      font-family: $font-family-base;
      color: #2e3b48;
    }

    .desc {
      width: 70%;
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    padding-top: 0;

    .title-wrap {
      .ico-wrap {
        margin-bottom: 6px;

        .ico {
          width: 38px;
          height: 38px;
        }
      }

      .title-h {
        font-size: 21px;
        font-weight: 700;
        margin-bottom: 6px;
      }

      .desc {
        width: 100%;
      }
    }
  }
}

.hostel-bedding-feature-section,
.hostel-events-feature-section,
.hostel-tours-feature-section,
.hostel-cafe-feature-section {
  position: relative;

  .hostel-feature-wrapper {
    padding: 0 0 64px 10%;
    overflow-x: scroll;
    position: relative;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .feature-cards-wrapper {
    display: inline-flex;
    margin-top: 12px;
    position: relative;
    padding-right: 12px;

    .feature-card {
      width: 360px;
      height: 560px;
      background-color: #eee;
      border-radius: 16px;
      box-shadow: rgba(38, 57, 77, 0.2) 0px 1px 15px;
      transition: all 0.3s linear;
      position: relative;
      overflow: hidden;
      margin: 0 12px;
      flex-shrink: 0;

      &:first-child {
        margin-left: 0;
      }

      .feature-img {
        width: 100%;
        height: 100%;
        position: relative;

        .pswp-gallery {
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        &::before {
          content: "";
          width: 100%;
          height: 40%;
          z-index: 50;
          background-image: linear-gradient(
            0deg,
            rgba(255, 255, 255, 1) 25%,
            rgba(255, 255, 255, 1) 10%,
            rgba(255, 255, 255, 0) 100%
          );

          position: absolute;
          left: 0;
          bottom: 0;

          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
        }
      }

      .feature-detail {
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 100;
        padding: 16px;

        .feature-title {
          font-size: 21px;
          font-weight: 800;
          margin-bottom: 4px;
          margin-top: 8px;
          position: relative;
        }

        .feature-desc {
          font-size: 14px;
          margin: 0;
        }

        .price {
          font-size: 16px;
          margin-bottom: 0;
          margin-top: 4px;
          font-weight: 400;
        }

        a,
        button {
          border: 1px solid #2e3b48;
          padding: 6px 12px;
          color: #2e3b48;
          float: right;
          background-color: transparent;
          transition: all 0.4s ease-in-out;
          font-size: 14px;

          &:hover {
            cursor: pointer;
            color: #ffffff;
            background-color: #2e3b48;
          }
        }
      }

      &:hover {
        transform: scale(1.008);
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    .hostel-feature-wrapper {
      padding: 0 0 48px 4%;
    }

    .feature-cards-wrapper {
      .feature-card {
        width: 320px;
        height: 480px;

        .feature-detail {
          .feature-title {
            font-size: 18px;
          }

          .feature-desc {
            font-size: 14px;
          }

          .price {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.hostel-exclusive-section {
  padding: $space * 12 0 $space * 22 0;
  overflow: hidden;

  .string {
    font-family: $font-family-headings;
    text-transform: uppercase;
    font-size: 104px;
    white-space: nowrap;
    line-height: 1.2;
    color: #f94601;
    letter-spacing: 2px;

    &.stroked {
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 0.06rem;
    }

    &.red-text {
      -webkit-text-stroke-color: #f94601;
    }

    &.purple-text {
      -webkit-text-stroke-color: #7437ae;
    }

    &.blue-text {
      -webkit-text-stroke-color: #2574a8;
    }

    &.green-text {
      -webkit-text-stroke-color: #689b6e;
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    padding: $space * 8 0 $space * 4 0;

    .string {
      font-size: 48px;
    }
  }
}

.amn-section {
  padding: 6rem 0 6rem 0;
  background-color: #f8f8f8;

  .title-wrap {
    width: 70%;
    margin: 0 auto;

    margin-bottom: 4rem;

    h2 {
      font-size: 42px;
      font-weight: 700;
      margin-bottom: 6px;
      font-family: $font-family-base;
      color: #2e3b48;
    }
  }

  .amn-wrapper {
    width: 70%;
    margin: 0 auto;

    justify-content: center;
  }

  .amn-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    gap: 26px;

    margin-bottom: 4rem;

    .amn-title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 6px;
      font-family: $font-family-base;
      color: #2e3b48;

      text-align: center;
    }

    .ico-wrap {
      width: 64px;
      height: 64px;

      position: relative;

      .bg-ico {
        content: "";
        width: 100%;
        height: 100%;

        position: absolute;
        left: 0;
        top: 0;

        z-index: 1;

        background-image: url("../img/blob-lt.svg");
        background-repeat: no-repeat;
        background-size: contain;

        transform: scale(2.8) translateY(4px);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;

        position: relative;
        z-index: 2;

        transform: scale(1);
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    padding: 4rem 0 4rem 0;
    margin-bottom: 4rem;

    .title-wrap {
      width: 100%;

      h2 {
        font-size: 28px;
      }
    }

    .amn-wrapper {
      width: 100%;
    }

    .amn-card {
      gap: 32px;

      .amn-title {
        font-size: 14px;
      }
    }
  }
}
