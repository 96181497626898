@use "sass:math";

.footer {
  &-section {
    padding: $space 0 0 0;
    height: 740px;
    background-image: url("../img/footer.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    @media (max-width: 767px) and (orientation: portrait) {
      height: 1080px;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #2e3b486e;
      z-index: 100;
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      z-index: 110;
      background-image: linear-gradient(
        360deg,
        rgba(46, 59, 72, 1) 20%,
        rgba(46, 59, 72, 1) 10%,
        rgba(255, 252, 252, 0) 100%
      );

      position: absolute;
      left: 0;
      top: 0;
    }

    .social-links {
      display: flex;
      align-items: center;
      margin: $space + 1 0 $space 0;
      justify-content: center;

      li {
        margin: 0 ($space - 0.5);

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .footer-contact-wrap,
    .footer-address-wrap,
    .footer-other-wrap {
      h3 {
        font-family: $font-family-base;
        font-weight: bolder;
        margin-bottom: 4px;
        color: #ffffff;
      }

      h6 {
        font-family: $font-family-base;
        font-size: 22px;
        font-weight: 700;
        color: #ffffff;
        margin-bottom: 0;
      }

      p,
      a,
      li {
        color: #ffffff;
      }

      a {
        transition: all 0.3s ease-in-out;

        &:hover {
          color: #ffffff;
        }
      }
    }

    .footer-contact-wrap {
      align-items: center;
      justify-content: center;

      .nav-logo {
        width: 96px;
        height: 96px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }

    .footer-address-wrap {
      p {
        color: #ffffff !important;
      }
    }

    .footer-address-wrap {
      padding-left: $space + 0.5;

      p {
        color: #ffffff;
      }

      .timings {
        margin-bottom: 0;

        span {
          font-weight: bold;
        }
      }

      @media (max-width: 767px) and (orientation: portrait) {
        padding-left: 0;
        margin-bottom: 32px;
      }
    }

    .footer-other-wrap {
      ul {
        &.hidden {
          display: none;
        }
      }
      li {
        margin-bottom: $space - 0.75;

        a {
          font-size: 16px;
        }
      }
    }
  }

  &-contact-wrap {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ffffff;

    .prm-contact {
      font-size: 26px;
      font-weight: 300;
      color: #2761b7 !important;
    }

    .prm-contact,
    .sec-contact {
      margin-bottom: 6px;
    }

    @media (max-width: 767px) and (orientation: portrait) {
      border-right: none;
      margin-bottom: 32px;

      .prm-contact,
      .sec-contact {
        margin-bottom: 12px;
      }
    }
  }

  &-upper-wrapper {
    position: relative;
    z-index: 150;
    margin-top: auto;
    padding: ($space + 0.25) 10%;
    color: $white;
    height: calc(100% - 64px);
    display: flex;
    .row {
      width: 100%;
      flex-shrink: 0;
      margin-top: auto;
    }
  }

  &-lower-wrapper {
    position: relative;
    z-index: 150;
    background-color: #ffffff;
    padding: ($space + 0.25) ($space + 0.5);
    height: 64px;

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: #2e3b48;
      display: flex;
      align-items: center;

      span {
        margin-left: auto;
        font-size: 12px;
        a {
          text-decoration: underline;
        }
      }
    }

    @media (max-width: 767px) and (orientation: portrait) {
      padding: ($space - 0.25) ($space + 0.5);
      height: auto;
      p {
        font-size: 12px;
        flex-direction: column;

        span {
          font-size: 10px;
          margin: 21px auto 0 auto;
        }
      }
    }
  }
}

.social-ico {
  flex-shrink: 0;
  height: 26px;
  width: 26px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ffffff;
  transition: all 0.3s ease-in-out;

  &.facebook {
    -webkit-mask: url("../social-ico/facebook.svg") no-repeat center;
    mask: url("../social-ico/facebook.svg") no-repeat center;

    &:hover {
      background-color: #1877f2;
    }
  }

  &.twitter {
    -webkit-mask: url("../social-ico/twitter.svg") no-repeat center;
    mask: url("../social-ico/twitter.svg") no-repeat center;

    &:hover {
      background-color: #1da1f2;
    }
  }

  &.instagram {
    -webkit-mask: url("../social-ico/instagram.svg") no-repeat center;
    mask: url("../social-ico/instagram.svg") no-repeat center;

    &:hover {
      background-color: #e4405f;
    }
  }

  &.youtube {
    -webkit-mask: url("../social-ico/youtube.svg") no-repeat center;
    mask: url("../social-ico/youtube.svg") no-repeat center;

    &:hover {
      background-color: #ff0000;
    }
  }

  &.linkedin {
    -webkit-mask: url("../social-ico/linkedin.svg") no-repeat center;
    mask: url("../social-ico/linkedin.svg") no-repeat center;

    &:hover {
      background-color: #0a66c2;
    }
  }
}
