.cookieConsentContainer {
  z-index: 999;

  width: 350px;

  min-height: 20px;

  box-sizing: border-box;

  padding: 30px 30px 30px 30px;

  background: #232323;

  overflow: hidden;

  position: fixed;

  bottom: 18px;

  right: 18px;

  display: none;
}

.cookieConsentContainer .cookieTitle a {
  font-family: OpenSans, arial, "sans-serif";

  color: #ffffff;

  font-size: 22px;

  line-height: 20px;

  display: block;
}

.cookieConsentContainer .cookieDesc p {
  margin: 0;

  padding: 0;

  font-family: OpenSans, arial, "sans-serif";

  color: #ffffff;

  font-size: 13px;

  line-height: 20px;

  display: block;

  margin-top: 10px;
}
.cookieConsentContainer .cookieDesc a {
  font-family: OpenSans, arial, "sans-serif";

  color: #ffffff;

  text-decoration: underline;
}

.cookieConsentContainer .cookieButton a {
  display: inline-block;

  font-family: OpenSans, arial, "sans-serif";

  color: #ffffff;

  font-size: 14px;

  font-weight: bold;

  margin-top: 14px;

  background: #000000;

  box-sizing: border-box;

  padding: 15px 24px;

  text-align: center;

  transition: background 0.3s;
}

.cookieConsentContainer .cookieButton a:hover {
  cursor: pointer;
  background: #7437ae;
}

.cookieConsentContainer .cookieButton a:active {
  cursor: pointer;
  background: #7437ae;
}

@media (max-width: 980px) {
  .cookieConsentContainer {
    bottom: 0px !important;

    left: 0px !important;

    width: 100% !important;
  }
}
